<template>
  <div class="wap-shop-auth">
    <div class="step-box">
      <div class="flex-center step-line">
        <div class="step flex-column-center" :class="stepIndex > 0 ? 'active' : ''">
          <div class="icon"></div>
          <div class="label">{{ $t('dian-pu-she-zhi') }}</div>
        </div>
        <div class="step flex-column-center" :class="stepIndex > 1 ? 'active' : ''">
          <div class="icon"></div>
          <div class="label">{{ $t('dian-pu-ren-zheng') }}</div>
        </div>
        <div class="step flex-column-center">
          <div class="icon"></div>
          <div class="label">{{ $t('shang-jia-shang-pin') }}</div>
        </div>
      </div>

      <div class="info-box flex-column-center">
        <div class="info">
          {{
            $t(
              'qing-nin-wan-shan-dian-pu-xin-xi-yi-bao-zheng-gu-ke-neng-zheng-chang-fang-wen-dao-nin'
            )
          }}
        </div>
        <el-button
          type="primary"
          size="small"
          @click="toSetting"
          v-if="stepIndex == 0"
          >{{ $t('li-ji-she-zhi') }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="toAuth"
          v-if="stepIndex == 1"
          >{{ $t('li-ji-ren-zheng') }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="toProduct"
          v-if="stepIndex == 2"
          >{{ $t('shang-jia-shang-pin') }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stepIndex: 0
    }
  },
  computed: {
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
    FirstSetup() {
      return this.$store.state.FirstSetup
    }
  },
  watch: {
    isShopAuth() {
      this.init()
    },
    FirstSetup() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.stepIndex = 0
      if (this.FirstSetup && this.isShopAuth) {
        this.stepIndex = 2
      } else if (this.FirstSetup && !this.isShopAuth) {
        this.stepIndex = 1
      }
    },
    toSetting() {
      this.$router.push({
        name: 'wapBasicInfo'
      })
    },
    toAuth() {
      this.$router.push({
        name: 'wapAuth'
      })
    },
    toProduct() {
      this.$router.push({
        name: 'wapProduct'
      })
    }
  }
}
</script>